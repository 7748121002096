export const base64ToBlob = (base64: string, contentType: string): Blob => {
  try {
    // Validate base64 string
    if (!/^(?:[A-Za-z\d+/]{4})*?(?:[A-Za-z\d+/]{2}==|[A-Za-z\d+/]{3}=)?$/.test(base64)) {
      throw new Error('Invalid base64 string');
    }

    // Decoding the base64 string.
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: contentType });
  } catch (error) {
    console.error('Error in base64ToBlob:', error);
    throw error;
  }
};

export const formatCurrency = (value: number): string => {
  if (!value) return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0);
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}

export const agGridformatCurrency = (params: any): string => {
  return formatCurrency(params.value);
}

export const capitalize = (str: string): string => {
  if (str && str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str
}

export const workflowStateValueFormatter = (params: any) => {
  const stateMap: { [key: string]: string } = {
    draft: "Draft",
    complete: "Complete",
  };
  return stateMap[params.value] || params.value;
};

export const clientValueFormatter = (params: any) => {
  const stateMap: { [key: string]: string } = {
    "Liquid Death": "Liquid Death",
    "Sovereign Brands": "Sovereign Brands",
  };
  return stateMap[params.value] || params.value;
};

export const mapWorkflowStatus = (str: string): string => {
  if (!str) return str;
  if (str.toLocaleLowerCase() === 'complete') {
    return 'Submitted'
  } else if (str.toLocaleLowerCase() === 'draft') {
    return 'Draft'
  }
  return capitalize(str)
}

export const validateEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}